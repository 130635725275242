/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const scrollToElement = require('scroll-to-element');

exports.onRouteUpdate = ({ location }) => {
  checkHash(location);
};

const checkHash = ({ hash }) => {
  if (hash) {
    scrollToElement(hash, {
      duration: 1000,
    });
  }
};
